import firebase from 'firebase';


export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const ValidatorUniqueEmail = async email => {
  // const db = firebase.firestore();
  // let signInMethods = [];
  // await db.collection("users")
  //   .where("email", "==", email)
  //   .get()
  //   .then((querySnapshot) => {
  //     querySnapshot.forEach((doc) => {
  //       signInMethods.push({
  //         id: doc.id,
  //         email: doc.data().email,
  //         type: doc.exists,
  //       });
  //     })
  //   });
  
  const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
  // console.log(signInMethods);
  return signInMethods.length <= 0;
}

export const ValidatorUniqueEmailExceptOld = async email => {
  const db = firebase.firestore();
  var id = localStorage.getItem("userSessionId");
  console.log(id);
  if(id){
  let user = await db.collection("users").doc(id).get();
  let oldEmail = user.data().email;
  if (oldEmail == email) {
    return true
  }
 } else {
    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
    // console.log(signInMethods);
    return signInMethods.length <= 0;
  };

}

export const validatorEmailExist = async email => {
  console.log('working');
  const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
  // console.log(signInMethods);
  return signInMethods.length > 0;
}
